import React, {
  useRef, useState, useEffect, useContext, useCallback, ReactNode,
} from 'react';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Button as DXButton,
  Selection,
  Editing,
  StateStoring,
  MasterDetail,
  Toolbar,
  Item,
  ColumnChooser,
  Lookup,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { Button as ClearButton } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import { UserContext } from 'context/user';
import { EyeIcon } from '@heroicons/react/outline';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { parseDate } from 'utility/formatDate';
import dayjs from 'dayjs';
import Button from 'components/basic/button/Button';
import getPersistentData from 'utility/getPersistentData';
import setPersistentData from 'utility/setPersistentData';

export interface IColumns {
  allowResizing?: boolean;
  allowGrouping?: boolean;
  fixed?: boolean;
  fixedPosition?: 'left' | 'right';
  dataField?: string | number;
  dataType?: string;
  caption?: string;
  buttonText?: string;
  format?: string | unknown;
  type?: string;
  name?: string;
  width?: string | number;
  selectedFilterOperation?: string;
  alignment?: 'left' | 'right' | 'center';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellRender?: (cell: any) => JSX.Element | React.ReactNode;
  minWidth?: number | string;
  cssClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visible?: boolean | ((cell: any) => boolean);
  onClick?: (...args) => void;
  customizeText?: string;
  trueText?: string;
  falseText?: string;
  lookup?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allowClearing?: boolean;
    dataSource: any[];
    displayExpr: string;
    valueExpr: string;
  };
  showInColumnChooser?: boolean;
  hideIfUnauthorized?: boolean; // only for buttons column
}
interface ITableProps {
  onAdd?: (...args) => void;
  onEdit?: (...args) => void;
  onView?: (...args) => void;
  onDelete?: (...args) => void;
  onSelectionChanged?: (...args) => void;
  onRowClicked?: (...args) => void;
  setVisibleButton?: (...args) => boolean;
  onRowPrepared?: (...args) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRowKeys?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: any[];
  isLoading: boolean;
  columns: IColumns[];
  keyExpr: string;
  roleLevel: number;
  selectionMode?: 'multiple' | 'single' | 'none';
  allowUpdating?: boolean;
  allowDeleting?: boolean;
  allowViewing?: boolean;
  allowApprove?: boolean;
  allowSelectAll?: boolean;
  allowColumnResizing?: boolean;
  columnResizingMode?: 'nextColumn' | 'widget';
  showClearFilterButton?: boolean;
  showExportButton?: boolean;
  filterRow?: boolean;
  headerFilter?: boolean;
  isWrapText?: boolean;
  isAllowedDelete?: boolean;
  storageKey?: string;
  enableMasterDetail?: boolean;
  enableColumnChooser?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masterDetailComponent?: any;
  onRowExpanded?: (...args) => void;
  triggerDeselectAll?: boolean;
  triggerRefresh?: boolean;
  onAddHeader?: ((...args) => void) | ReactNode | null;
  searchPanel?: boolean;
  editingMode?: 'custom' | 'row' | 'batch' | 'cell' | 'form' | 'popup';
}

function Table(props: ITableProps) {
  const {
    onAdd,
    onEdit,
    onDelete,
    onView,
    onRowPrepared,
    setVisibleButton,
    dataSource,
    isLoading,
    columns,
    keyExpr,
    roleLevel,
    selectionMode,
    allowUpdating,
    allowDeleting,
    allowViewing,
    allowApprove,
    showClearFilterButton,
    showExportButton,
    filterRow,
    headerFilter,
    isWrapText,
    selectedRowKeys,
    onSelectionChanged,
    allowSelectAll,
    isAllowedDelete,
    storageKey,
    enableMasterDetail,
    masterDetailComponent,
    onRowExpanded,
    triggerDeselectAll,
    triggerRefresh,
    onAddHeader,
    searchPanel,
    allowColumnResizing,
    enableColumnChooser,
    columnResizingMode,
    editingMode,
    onRowClicked,
  } = props;

  const dataGridRef = useRef(null);
  const pageSizes = [10, 20, 50, 100];
  const userContext = useContext(UserContext);
  const [isAccessAllowed, setAccessAllowed] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false,
  });

  const handleClearFilter = () => dataGridRef.current.instance.clearFilter();
  const handleClickAdd = (e) => onAdd(e.row.key);
  const handleClickEdit = (e) => onEdit(e.row.key, e.row.data);
  const handleClickView = (e) => onView(e.row.key, e.row.data);
  const handleClickDelete = (e) => {
    confirm('<span style="text-align:center">Are you sure?</span>', 'Delete').then((chosen) => {
      if (!chosen) return;
      onDelete(e.row.key, e.row.data);
    });
  };

  const getUserAccess = async () => {
    setAccessAllowed({
      view: true,
      add: roleLevel > 10000,
      edit: roleLevel > 10000,
      delete: roleLevel > 10000,
    });
  };

  const onClickType = {
    add: handleClickAdd,
    edit: handleClickEdit,
    delete: handleClickDelete,
    view: handleClickView,
  };

  const allowedTableMethod = {
    add: allowUpdating,
    edit: allowUpdating,
    delete: allowDeleting,
    view: allowViewing,
    approve: allowApprove,
    accessFI: allowUpdating,
  };

  const ButtonIcons = {
    add: () => <i className="dx-icon-add text-blue-600 cursor-pointer" />,
    edit: () => <i className="dx-icon-edit text-blue-600 cursor-pointer" />,
    view: () => <EyeIcon className="text-blue-600 cursor-pointer h-3.5" />,
    delete: () => <i className="dx-icon-trash text-red-600 cursor-pointer" />,
    approve: () => <i className="dx-icon-check text-blue-600 cursor-pointer" />,
    confirm: () => <i className="dx-icon-taskinprogress text-green-300 cursor-pointer" />,
    accessFI: () => (
      <i className="dx-icon-check text-blue-600 cursor-pointer" />
    ),
  };

  function refreshExpandedRow(e) {
    const { component } = e;

    component.repaintRows([component.getRowIndexByKey(e.key) + 1]);
  }

  const exportGrids = useCallback(() => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      worksheet,
      component: dataGridRef.current.instance,
      autoFilterEnabled: true,
    }).then(() => {
      const columnWidths = [
        25,
        25,
        25,
        25,
        25,
        20,
        20,
        20,
        20,
      ];

      columnWidths.forEach((width, index) => {
        worksheet.getColumn(index + 1).width = width;
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `listLoan-${parseDate('file_datetime', dayjs())}.xlsx`);
      });
    });
  }, []);

  const refreshDataGrid = () => {
    dataGridRef.current.instance.refresh();
  };
  const repaintDataGrid = () => {
    dataGridRef.current.instance.repaint();
  };
  const deselectAll = () => {
    dataGridRef.current.instance.deselectAll();
  };
  const beginCustomLoading = (messageText = 'Loading...') => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.beginCustomLoading(messageText);
    }
  };
  const endCustomLoading = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.endCustomLoading();
    }
  };
  const saveState = useCallback(async () => {
    const state = await dataGridRef.current.instance.state();
    setPersistentData(storageKey, JSON.stringify(state));
  }, [storageKey]);
  const loadState = useCallback(async () => {
    const state = getPersistentData(storageKey);
    await dataGridRef.current.instance.state(JSON.parse(state));
  }, [storageKey]);

  useEffect(() => {
    if (triggerDeselectAll !== null && triggerDeselectAll !== undefined) {
      deselectAll();
    }
  }, [triggerDeselectAll]);

  useEffect(() => {
    if (triggerRefresh !== null && triggerRefresh !== undefined) {
      refreshDataGrid();
      repaintDataGrid();
    }
  }, [triggerRefresh]);

  useEffect(() => {
    if (isLoading) {
      beginCustomLoading();
    } else {
      endCustomLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    getUserAccess();
  }, [roleLevel]);

  return (
    <div className="relative text-right">
      <DataGrid
        dataSource={dataSource}
        paging={{ pageSize: 20 }}
        filterRow={{ visible: filterRow }}
        headerFilter={{ visible: headerFilter }}
        showBorders
        rowAlternationEnabled
        allowColumnResizing={allowColumnResizing}
        columnResizingMode={columnResizingMode}
        columnAutoWidth
        keyExpr={keyExpr}
        wordWrapEnabled={isWrapText}
        selectedRowKeys={selectedRowKeys}
        ref={dataGridRef}
        onSelectionChanged={onSelectionChanged}
        onRowExpanded={onRowExpanded ? (e) => onRowExpanded(e) : (e) => refreshExpandedRow(e)}
        onRowClick={(e) => onRowClicked(e)}
        onRowPrepared={onRowPrepared}
      >
        <SearchPanel visible={searchPanel} width={160} />
        <Toolbar>
          <Item
            location="before"
            visible={onAddHeader && isAccessAllowed.add}
            render={() => {
              if (typeof onAddHeader === 'function') {
                return <Button value="Add" onClick={onAddHeader} />;
              }
              return onAddHeader;
            }}
          />
          <Item
            location="after"
            visible={showExportButton}
            render={() => <Button value="Export Data" onClick={exportGrids} />}
          />
          <Item name="columnChooserButton" />
          <Item location="after" name="searchPanel" />
          <Item location="after" visible={showClearFilterButton}>
            <ClearButton type="normal" text="Clear Filter" onClick={handleClearFilter} />
          </Item>
        </Toolbar>
        <ColumnChooser enabled={enableColumnChooser} mode="select" />
        <Selection mode={selectionMode} allowSelectAll={allowSelectAll} selectAllMode="page" showCheckBoxesMode="always" />
        {editingMode !== 'custom' && (
          <Editing refreshMode="reshape" mode={editingMode} useIcons allowUpdating={allowUpdating} allowDeleting={allowDeleting} confirmDelete />
        )}
        {storageKey !== '' && <StateStoring enabled type="custom" customLoad={loadState} customSave={saveState} savingTimeout={0} />}
        {columns.map((c) => {
          const isVisible = () => {
            if (c.hideIfUnauthorized && c.type === 'buttons') {
              if (allowedTableMethod[c.name] && isAccessAllowed[c.name]) {
                return c.visible;
              }
              return false;
            }
            return c.visible;
          };
          const isColumnVisible = isVisible();
          return (
            <Column
              allowResizing={c.allowResizing}
              allowGrouping={c.allowGrouping}
              fixed={c.fixed}
              fixedPosition={c.fixedPosition}
              name={c.dataField || c.name}
              key={c.dataField || c.name}
              dataField={c.dataField}
              dataType={c.dataType}
              caption={c.caption}
              format={c.format}
              type={c.type}
              width={c.width}
              selectedFilterOperation={c.selectedFilterOperation}
              alignment={c.alignment}
              cellRender={c.cellRender}
              cssClass={c.cssClass}
              minWidth={c.minWidth}
              visible={isColumnVisible ?? true}
              customizeText={c.customizeText}
              falseText={c.falseText}
              trueText={c.trueText}
              showInColumnChooser={c.showInColumnChooser}
            >
              {c.lookup && (
                <Lookup
                  dataSource={c.lookup.dataSource}
                  displayExpr={c.lookup.displayExpr}
                  valueExpr={c.lookup.valueExpr}
                  allowClearing={c.lookup.allowClearing}
                />
              )}
              {c.type === 'buttons' && (
                <DXButton
                  cssClass="flex justify-center items-center"
                  text={c.buttonText}
                  name={c.name}
                  onClick={c.onClick ? (e) => c.onClick(e.row.key, e.row.data) : onClickType[c.name]}
                  render={ButtonIcons[c.name]}
                  visible={allowedTableMethod[c.name] && isAccessAllowed[c.name] && setVisibleButton && (c.visible || true)}
                />
              )}
            </Column>
          );
        })}

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={false} infoText="Page {0} of {1} ({2} items)" showInfo showNavigationButtons visible />
        <Paging defaultPageSize={20} />
        <MasterDetail enabled={enableMasterDetail} component={masterDetailComponent} />
      </DataGrid>
    </div>
  );
}

Table.defaultProps = {
  onAdd: () => null,
  onEdit: () => null,
  onView: () => null,
  onDelete: () => null,
  onSelectionChanged: () => null,
  setVisibleButton: () => true,
  onRowClicked: () => null,
  onRowPrepared: () => null,
  selectedRowKeys: [],
  selectionMode: 'none',
  allowSelectAll: false,
  allowUpdating: true,
  allowDeleting: true,
  allowViewing: true,
  allowApprove: true,
  allowColumnResizing: false,
  columnResizingMode: 'widget',
  showClearFilterButton: true,
  showExportButton: false,
  filterRow: true,
  headerFilter: true,
  isWrapText: true,
  isAllowedDelete: false,
  storageKey: '',
  enableColumnChooser: false,
  enableMasterDetail: false,
  masterDetailComponent: null,
  onRowExpanded: null,
  triggerDeselectAll: undefined,
  triggerRefresh: undefined,
  onAddHeader: null,
  searchPanel: false,
  editingMode: 'custom',
};

export default Table;
